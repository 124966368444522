
export const escapeStringJS = function (str) {
  if (!str || str.trim().length < 1) {
    return str;
  }
  return str.replace(/"/gi, '&quot;').replace(/'/gi, '&apos;');
}

export default {
  escapeStringJS: escapeStringJS,
}
