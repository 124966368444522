<template>
  <section class="page-container">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader color="black"  v-if="loading"/>
    <div v-else class="areas-container">
      <span
        class="page-title"
        v-if="!isNotEmpty && isLivePage"
      >
        Live
      </span>
      <areas
        :scroll="true"
        :areas="areas"
        :page="headerPageType"
        :profile="channelProfile"
        :current-live="liveEventArea"
        :badges="badges"
        :page-item="head"
      />

      <div
        v-if="head.creator"
        class="yotpo yotpo-main-widget"
        :data-product-id="head.creator.id"
        :data-name="head.creator.name"
        :data-image-url="metadata.image"
        :data-url="metadata.url"
      />
      <search-error
        v-if="!isNotEmpty && isLivePage"
        :problem-text="'Sorry, there are no live shows to display. Please check back later.'"
      />
    </div>
    <b-modal ref="error_modal" :title="modalTitle" hide-footer>
      <span v-t="'MODAL.PAGE.CONTENT'"/>
    </b-modal>
  </section>
</template>

<script>
  import Areas from '@/components/areas/areas';
  import Loader from '@/components/loader/loader';
  import JsonLD from '@/service/jsonld';
  import { escapeStringJS } from '@/utils/escape';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';
  import { ScreenReadyMixin } from '@/plugins/mixin';
  import { getMetaIos } from '@/utils/generateDeepLink';
  import { amsClient } from '@/service/ams';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';
  import rtm from '@/service/realTimeMessages';
  import ErrorPage from '../ErrorPage/ErrorPage';
  import SearchError from '@/components/searchError/searchError';
  import badgesFilter from 'src/filters/expiredBadgesFilter';
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';

  export default {
    name: 'PageContainer',
    components: {
      SearchError,
      ErrorPage,
      Areas,
      Loader,
    },
    mixins: [ScreenReadyMixin],

    data() {
      return {
        loading: true,
        liveEventArea: null,
        areas: [],
        subscribers: [],
        context: {},
        content: null,
        head: {},
        localContext: {},
        pageAds: {},
        currentPage: null,
        reloadComponent: true,
        metadata: {},
        channelProfile: null,
        currentChannel: null,
        isNotEmpty: true,
        prevTitle: '',
      };
    },
    computed: {
      // ...mapGetters({
      //   areasLive: 'dataAreasLive/areasLive',
      // }),
      isLivePage() {
        return this.$route.path === '/screen/live/';
      },
      headerPageType() {
        return 'section';
      },
      modalTitle() {
        return this.$t('MODAL.PAGE.TITLE');
      },
      isLiveRoute() {
        return this.$route.path === '/screen/live/';
      },
      seoTitle() {
        return this.head.creator.name;
      },
      seoDescription() {
        return this.head.creator.description.replaceAll(/<[^>]*>/g, '');
      },
      seoImage() {
        return this.head.creator.picture;
      },
      badges() {
        return badgesFilter(this.head.creator?.badges);
      },
      pageForCurrentLive() {
        return this.$store.getters['player/pageForCurrentLive'];
      },
      pageForSecondLive() {
        return this.$store.getters['player/pageForSecondLive'];
      },
      finishedCurrentLive() {
        if (!this.liveEventArea) {
          return null
        }

        return this.$store.getters['dataAreasLive/finishedCurrentLive'](this.liveEventArea.actualEvent);
      },
    },
    watch: {
      loading(val) {
        if (val === false) {
          this.setScreenReady(this.head, this.screenRequestTime);
        }
      },

      finishedCurrentLive: {
        deep: true,
        handler(value) {
          if (value?.end) {
            this.removeEventPlayer();
            // this.updateTitle(this.prevTitle);
          }
        },
      },
    },
    created() {
      this.$bus.$on('segmentIsNotEmpty', this.setIsNotEmpty);
    },
    beforeDestroy() {
      this.$bus.$off('segmentIsNotEmpty', this.setIsNotEmpty);
      rtm.off(this.subscribers);
      this.removeEventPlayer();
      JsonLD.pop();
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.loading = true;
        vm.getData(to);
      });
    },
    beforeRouteUpdate(to, from, next) {
      this.removeEventPlayer();
      this.currentPage = null;
      JsonLD.pop();
      this.loading = true;
      this.getData(to);
      rtm.off(this.subscribers);
      next();
    },

    methods: {
      updateTitle(title) {
        if(!title) return;
        this.$bus.$emit('updateTitle', title);
      },
      fillHeader() {
        const isFilledMetadata = !!Object.keys(this.metadata).length;

        if (!isFilledMetadata) {
          this.metadata = {
            ...normaliseMetaTags(this.head),
            image: this.head.creator?.picture,
            description: this.head.creator?.description,
            url: `${location.origin}/portal/s/${this.head.creator?.slug}`,
          };
        }

        this.metadata = {
          ...normaliseMetaTags(this.head),
          ...this.metadata,
        };

        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': { content: 200 },
          'meta[name="apple-itunes-app"]': { content: getMetaIos(this.head) },
        });
        this.prevTitle = this.metadata.title;
      },

      addSchemaOrg(item) {
        const breadcrump = {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': window.location.origin + '/portal/',
                name: 'PROTV PLUS',
              },
            },
            {
              '@type': 'ListItem',
              position: 2,
              item: {
                '@id': window.location.origin + '/portal' + item.link,
                name: escapeStringJS(item.title),
              },
            },
          ],
        };

        JsonLD.push(breadcrump);
        JsonLD.update();
      },
      async handleActiveEvent(creatorScreen) {
        const eventComponent = {
          ...creatorScreen,
          title: 'Live',
          itype: 'custom_channel_event',
        };

        const actualEvent = await this.checkActualEvent(creatorScreen.creator.owner_id);

        if (actualEvent) {
          this.switchToEventSocket(actualEvent.id);
          this.updateTitle(actualEvent?.title);
          if (actualEvent.on_air) {
            eventComponent.actualEvent = actualEvent;

            this.addEventPlayer(eventComponent);
          }
        }

        rtm.off(this.subscribers);

        this.subscribers.push(
          rtm.on(['started'], async (data) => {
            if (this.liveEventArea || this.isLiveRoute) {
              return;
            }

            const actualEvent = await this.checkActualEvent(creatorScreen.creator.owner_id, 5);

            if (actualEvent) {
              this.updateTitle(actualEvent?.title);
              this.switchToEventSocket(actualEvent.id);
              eventComponent.actualEvent = actualEvent;
              this.addEventPlayer(eventComponent);
            }
          }),
        );
      },
      addEventPlayer(eventComponent) {
        this.liveEventArea = eventComponent;
      },
      removeEventPlayer() {
        this.liveEventArea = null;
        this.switchToLobbySocket();
      },
      setIsNotEmpty(payload) {
        this.isNotEmpty = payload;
      },
      switchToLobbySocket() {
        if (rtm.roomId !== 'lobby') {
          rtm.init('lobby', null, {
            cleanSubscribes: false,
          });
        }
      },
      switchToEventSocket(eventId) {
        rtm.init(eventId, null, {
          cleanSubscribes: false,
        });
      },
      async getData(route) {
        if (route.meta.productPage) {
          try {
            const { head } = await amsClient.callAms(`/shoprzapi/product/${route.params.id}`);

            this.$router.replace({ path: head.page });
          } catch {
            this.$router.replace({ path: '/error' });
          }

          return;
        }

        const storeSlug = this.$isStandaloneStore ? this.$standaloneStoreName : route.params.id;


        const url = `/screen/${storeSlug}/`;

        this.screenRequestTime = this.setScreenRequest({
          id: storeSlug,
          itype: 'screen',
          link: url,
        });

        try {
          const data = await amsClient.callAms(url);
          this.setCurrentScreen(data.head);

          $stats.send('page_opened', {
            page_type: 'screen',
            channel_id: data.head?.creator?.id,
            store_id: 'verb',
          });
          this.currentChannel = data.head;

          if (this.$route.meta.seoPage) {
            this.handleSeoPage();
          };

          this.content = data.content;
          if (this.$route.meta.creatorPage && this.$route.path != '/screen/live/' ) {
            if (data.head?.creator) {
              this.channelProfile = {
                ...data.head,
                itype: 'custom_channel_profile',
              };
              this.handleActiveEvent(data.head);
            } else {
              console.warn('Creator not found for build the page!');
            }
          }
          else{
            this.channelProfile = null;
            this.removeEventPlayer();
          }

          data.head.cached = !!data.cached;
          this.head = data.head;
          this.setScreenResponse(this.head);
          this.fillHeader();
          this.addSchemaOrg(this.head);

          if (memoryStore.get('redirect')) {
            let err = memoryStore.get('redirect');
            this.showErrorModal(err.err, err.code);
            memoryStore.set('redirect', null);
          } else {
            this.showErrorModal(data.error);
          }

          this.areas = [...data.items];

          this.loading = false;
        } catch (e) {
          this.$plError.setError(e, 'network');
          this.$router.replace({ path: '/error' });
        }
      },
      async checkActualEvent(ownerId, attempts = 0) {
        const { items } = await amsClient.callAms('/on_air/', {
          cache: false,
          query: {
            owid: ownerId,
            ...(this.$eventsTimestamp && { t: this.$eventsTimestamp }),
          },
        });

        if (!items.length && attempts) {
          setTimeout(async () => {
            attempts -= 1;

            await this.checkActualEvent(ownerId, attempts);
          }, 2000);

          return;
        }

        return items.length ? items[0] : null;
      },
      showErrorModal(error_modal = null, code = null, redirect = null) {
        if (error_modal) {
          this.$refs.error_modal.show();
          code = 301;

          memoryStore.set('autoplay', false);
          memoryStore.set('close_backdrop', true);
        } else {
          memoryStore.set('autoplay', true);
        }
        let res = code ? { content: `${code}` } : { content: '200' };
        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': res,
        });
      },
      async handleSeoPage() {
        const path = this.$route.path;
        const productMatch = Array.from(path.matchAll(/^\/s\/(.*)\/pc\/(.*)\/p\/(.*)/gi));
        const vodMatch = Array.from(path.matchAll(/^\/s\/(.*)\/v\/(.*)/gi));
        const comingLiveMatch = Array.from(path.matchAll(/^\/s\/(.*)\/l\/(.*)/gi));
        if (productMatch.length) {
          const product = productMatch[0][3];

          const { head } = await amsClient.callAms(`/shoprzapi/product/${product}`);

          this.updatePageMetadata(head);

          this.$bus.$emit('toggleProductPopup', { item: head });
        } else if (vodMatch.length) {
          const vodPath = vodMatch[0][0];

          const { head } = await amsClient.callAms(vodPath);

          head.chat_id = head.event?.chat_id || '';
          head.chat_url = head.event?.chat_url || '';

          this.updatePageMetadata(head);

          this.$bus.$emit('togglePopupVOD', { item: head });
        } else if (comingLiveMatch.length) {
          const livePath = comingLiveMatch[0][0];

          const { head } = await amsClient.callAms(livePath);

          if (head.status === 'ready') {
            this.$router.push(`/s/${this.currentChannel.creator.slug}`);
            return;
          } else if (head.status === 'finished') {
            this.$router.push(`/s/${this.currentChannel.creator.slug}`);

            this.$bvModal.msgBoxOk('That Live is finished', {
              centered: true,
              size: 'confirm',
              okOnly: true,
            });

            return;
          }

          this.updatePageMetadata(head);

          this.$bus.$emit('ToggleScheduleEventDialog', {
            ...head,
            creator: this.currentChannel.creator,
          });
        }

        window.prerenderReady = true;
      },
      updatePageMetadata(item) {
        this.metadata = {
          ...this.metadata,
          title: item.title,
          description: item.description,
          image: item.backdrop || item.poster,
          url: `${this.$location}${item.share}`,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  $segment-border-color: #e5e8f1;

  .page-title {
    display: inline-block;
    padding: 40px 0 0 40px;
    font-weight: 700;
    font-size: 30px;
    line-height: 36.5px;
    color: $main-text-color;
  }
</style>
